<template>
    <k-object-selector
        :value="value"
        :label="label"
        @input="changed"
        :itemFunc="itemFunc"
        :suggestionsFunc="suggestionsFunc"
        objectName="card"
        objectNamePlural="cards"
    >
        <template slot="item" slot-scope="{item}">
            <v-list-item-content>
                <v-list-item-title>{{ item.card_info.number }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </k-object-selector>
</template>

<script>
import api from '@/services/api';

export default {
  props: ['value', 'label'],
  methods: {
    changed(value) {
      this.$emit('input', value);
    },
    itemFunc(id) {
      return api.kernel.get(`/cards/${id}`);
    },
    suggestionsFunc(search) {
      if (!search) {
        return api.kernel.get('/cards').then((data) => data.data);
      }
      return Promise.all([
        api.kernel.get(`/cards/${search}`).then((data) => [data], () => []),
      ]).then((datas) => [].concat(...datas));
    },
  },

};
</script>
